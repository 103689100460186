<template>
  <div>
    <v-btn>Test</v-btn>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

// Components
export default defineComponent({
  name: 'HomeView',

})
</script>
