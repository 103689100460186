import { defineStore } from 'pinia'
import { isEmpty } from 'lodash'

export const Users = defineStore('Users', {
  state: () => ({
    users: [],
  }),
  getters: {
    getUserById: (state) => (userId) => {
      const foundUser = state.users.find((user) => user.id === userId)
      if (foundUser && !isEmpty(foundUser)) {
        return foundUser
      }
      return false
    },
  },
  actions: {
    addUser(user) {
      if (this.getUserById(user.id)) {
        const index = this.users.findIndex((arrUser) => arrUser.id === user.id)
        this.users[index] = user
      } else {
        this.users.push(user)
      }
    },
  },
})
