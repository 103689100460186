import { defineStore } from 'pinia'
// eslint-disable-next-line import/no-cycle
import axios from '@/plugins/axios'

// eslint-disable-next-line import/prefer-default-export
export const useUserStore = defineStore('user', {
  state: () => ({
    userToken: '',
    userData: {},
  }),
  getters: {
    isLoggedIn: (state) => {
      if (state.userToken || window.getCookie('userToken')) {
        return true
      }
      return false
    },
    getUserToken: (state) => state.userToken || window.getCookie('userToken'),
  },
  actions: {
    loginRequest(params) {
      return new Promise((resolve, reject) => {
        axios.post('api/login', params)
          .then((r) => resolve(r))
          .catch((e) => reject(e))
      })
    },
    getUser() {
      return new Promise((resolve, reject) => {
        axios.get('/api/user')
          .then((r) => {
            this.userToken = r.data.token
            this.userData = r.data.user
            resolve(r)
          })
          .catch((errors) => {
            reject(errors)
          })
      })
    },
  },
})
