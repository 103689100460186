import axios from '@/plugins/axios'

export default class User {
  constructor(id, forename, surname, email, username, role) {
    this.id = id
    this.forename = forename
    this.surname = surname
    this.email = email
    this.username = username
    this.role = role
  }

  static constructFromId(id) {
    const newClass = new User(id, '', '', '', '', '')

    return newClass.reload()
  }

  static constructFromObject(attrs) {
    return new User(
      attrs.id,
      attrs.forename,
      attrs.surname,
      attrs.email,
      attrs.username,
      attrs.role,
    )
  }

  reload() {
    return new Promise((resolve, reject) => {
      axios.get(`/api/user/${this.id}`)
        .then(({ data }) => {
          this.forename = data.user.forename
          this.surname = data.user.surname
          this.email = data.user.email
          this.username = data.user.username
          this.role = data.user.role
          resolve(data)
        })
        .catch(reject)
    })
  }
}
