const pages = [
  {
    name: 'Pamps',
    to: 'pamps',
  },
  {
    name: 'Orders',
    to: 'orders',
  },
  {
    name: 'Rewards',
    to: 'home',
  },
  {
    name: 'Hypno',
    to: 'home',
  },
  {
    name: 'Chastity',
    to: 'chastity',
  },
]

export default pages
