// Styles
/* eslint-disable */
import '@mdi/font/css/materialdesignicons.css';
// eslint-disable-next-line import/extensions
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

// Vuetify
import { createVuetify } from 'vuetify';

// eslint-disable-next-line function-paren-newline
export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    ...components, 
    theme: {
      defaultTheme: 'dark'
    },
  },
  directives,
// eslint-disable-next-line function-paren-newline
);
