import axios from 'axios'
import VueAxios from 'vue-axios'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

// eslint-disable-next-line consistent-return,func-names
window.getCookie = function (name) {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
  if (match) return match[2]
}

// eslint-disable-next-line func-names
window.deleteCookie = function (name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

createApp(App).use(createPinia())
  .use(router)
  .use(vuetify)
  .use(VueAxios, axios)
  .mount('#app')
