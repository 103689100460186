import { defineStore } from 'pinia'
import axios from '@/plugins/axios'
import { isEmpty, sortBy } from 'lodash'
// import { useUserStore } from '@/store/UserStore';

// eslint-disable-next-line import/prefer-default-export
export const useSubStore = defineStore('sub', {
  state: () => ({
    subs: [],
    activeSub: {},
  }),
  getters: {
    getAllSubs: (state) => sortBy([...state.subs, state.activeSub].filter((sub) => !isEmpty(sub)), 'username'),
    getSubByUserId: (state) => (userId) => {
      const allSubs = [...state.subs, state.activeSub]
      const foundSub = allSubs.find((sub) => Number(sub.user?.id) === userId)

      if (foundSub) {
        return foundSub
      }
      return false
    },
  },
  actions: {
    fetchSubs() {
      return new Promise((resolve, reject) => {
        axios.get('/api/subs/index')
          .then((r) => {
            this.subs = r.data.subs
            this.activeSub = r.data.activeSub
            resolve(r)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },
    /**
     *
     * @param {Number} userId - ID of user related to auth user
     * @param {Object} params - FormData params
     * @returns {Promise<unknown>} - Axios Promise
     */
    updateSubConfig(userId, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/api/subs/${userId}/config`, params)
          .then((response) => resolve(response))
          .catch((errors) => reject(errors))
      })
    },
    /**
     *
     * @param userId
     * @returns {Promise<any>}
     */
    makeSubActive(userId) {
      return new Promise((resolve, reject) => {
        axios.post(`/api/subs/${userId}/active`, {})
          .then((response) => {
            this.subs = response.data.subs
            this.activeSub = response.data.activeSub

            resolve(response)
          })
          .catch((errors) => reject(errors))
      })
    },
    /**
     * Delete Sub from auth() user
     *
     * @param {Number} userId
     * @returns {Promise<unknown>}
     */
    deleteSub(userId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/subs/${userId}`, {})
          .then((response) => {
            this.subs = response.data.subs
            this.activeSub = response.data.activeSub
            resolve(response)
          })
          .catch((e) => reject(e))
      })
    },
    /**
     * Sends request to add a new sub
     * @param {string} subUsername
     * @param {string} inviteMessage
     * @returns {Promise<unknown>}
     */
    addSub(subUsername, inviteMessage) {
      return new Promise((resolve, reject) => {
        axios.post('/api/subs/add', { subUsername, inviteMessage })
          .then((r) => resolve(r))
          .catch((e) => reject(e))
      })
    },
  },
})
