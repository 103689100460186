/* eslint-disable */

import axios from 'axios';
import { useUserStore } from '@/store/UserStore';
import emitter from "@/tools/emitter";

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL = process.env.API_URL
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  baseURL: process.env.API_URL,
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization =  'Bearer ' + useUserStore().getUserToken;

    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
  ,
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) =>
    // Do something with response data
    response,
  (error) => {
    if (error.response && error.response.status >= 500) {
      emitter.emit('show-alert', {alertText: 'Something went wrong while communicating with the server, please try again', alertColor: 'red'})
    }
    // Return to outer function in-case we need to deal with validation
    Promise.reject(error)
  }
  ,
);


export default _axios;
