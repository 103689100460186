<template>
  <v-app>
    <MainLayout />
    <v-main>
      <router-view class="ma-5" />
    </v-main>
  </v-app>
</template>

<script>

import MainLayout from '@/layouts/MainLayout.vue'

export default {
  name: 'App',
  components: { MainLayout },

  data: () => ({
    //
  }),
}
</script>
