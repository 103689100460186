export default class CookieManager {
  /**
   * Deletes a cookie from the browser store
   * @param name : string
   */
  static deleteCookie(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }

  /**
   * Gets a cookie if it exists
   * @param name : string
   * @returns {undefined|string}
   */
  static getCookie(name) {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
    if (match) return match[2]
    return undefined
  }
}
