<script setup>
import { useSubStore } from '@/store/SubStore'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'
import emitter from '@/tools/emitter'

const subStore = useSubStore()
const { subs, activeSub } = storeToRefs(subStore)

onMounted(() => {
  subStore.fetchSubs()
})

const makeActive = (userId) => {
  subStore.makeSubActive(userId)
    .then((response) => {
      emitter.emit('show-alert', { alertText: response.data.message, alertColor: 'success' })
    })
}
</script>

<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn
        color="primary"
        v-bind="props"
      >
        Subs
      </v-btn>
    </template>
    <v-list v-if="subs">
      <v-list-subheader>Active sub:</v-list-subheader>
      <v-divider />
      <v-list-item>
        <v-list-item>{{ activeSub.name }}</v-list-item>
      </v-list-item>
      <v-list-subheader>Your subs:</v-list-subheader>
      <v-divider />
      <v-list-item
        v-for="(item, index) in subs"
        :key="index"
        @click="makeActive(item.id)"
      >
        <v-list-item-title class="text-center">
          {{ item.name }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item :to="{name: 'subs-index'}">
        <v-list-item-title class="text-center">
          Manage
          <v-icon
            size="small"
            right
          >
            mdi-open-in-new
          </v-icon>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped>

</style>
