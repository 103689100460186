import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/store/UserStore'
import { Users } from '@/store/Users'
import User from '@/store/User'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/pamps',
    name: 'pamps',
    component: () => import('../views/PampView.vue'),
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/OrdersView.vue'),
  },
  {
    path: '/orders/:orderId',
    name: 'order-show',
    props: true,
    component: () => import('../views/OrderShow.vue'),
  },
  {
    path: '/subs',
    name: 'subs-index',
    component: () => import('../views/SubsIndexView.vue'),
  },
  {
    path: '/chastity',
    name: 'chastity',
    component: () => import('../views/ChastityView.vue'),
  },
  {
    path: '/invite-accept/:notificationId',
    name: 'invite-accept',
    props: true,
    component: () => import('../views/Emails/SubInviteResponse.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to) => {
  const userStore = useUserStore()
  const usersStore = Users()
  if (to.name !== 'login' && !userStore.isLoggedIn) {
    return { name: 'login' }
  }

  let UserGetFailed = false

  if (to.name !== 'login') {
    userStore.getUser()
      .then((response) => {
        const user = User.constructFromObject(response.data.user)
        usersStore.addUser(user)
      })
      .catch(() => {
        UserGetFailed = true
      })

    if (UserGetFailed) {
      return { name: 'login' }
    }
  }

  return true
})

// eslint-disable-next-line no-undef
export default router
