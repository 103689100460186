<script setup>
import MainNav from '@/navigation/mainNav'
import { ref } from 'vue'
import { useUserStore } from '@/store/UserStore'
import { getActivePinia, storeToRefs } from 'pinia'
import SubMenu from '@/components/SubMenu.vue'
import { useRouter } from 'vue-router'
import CookieManager from '@/tools/CookieManager'
import emitter from '@/tools/emitter'

const drawer = ref(false)
const userStore = useUserStore()
const { isLoggedIn } = storeToRefs(userStore)
const router = useRouter()
const snackbar = ref(false)
const snackbarText = ref('')
const snackbarColor = ref('gray')

const showAlert = ({ alertText, alertColor }) => {
  snackbarText.value = alertText
  snackbarColor.value = alertColor
  snackbar.value = true
}

emitter.on('show-alert', showAlert)

const logout = () => {
  // Delete from store
  // eslint-disable-next-line no-underscore-dangle
  getActivePinia()._s.forEach((store) => store.$reset())

  // Delete cookie
  CookieManager.deleteCookie('userToken')

  // Push to login
  router.push({ name: 'login' })
}
</script>

<template>
  <div>
    <v-app-bar>
      <template #prepend>
        <v-app-bar-nav-icon
          v-if="isLoggedIn"
          @click.stop="drawer = !drawer"
        />
      </template>

      <v-app-bar-title>Slave App</v-app-bar-title>

      <template
        v-if="isLoggedIn"
        #append
      >
        <v-btn @click="logout">
          Logout
        </v-btn>

        <sub-menu />
      </template>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      location="start"
      clipped
    >
      <v-list>
        <v-list-item
          v-for="item in MainNav"
          :key="item"
          :to="{name: item.to}"
        >
          {{ item.name }}
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-snackbar
      v-model="snackbar"
      timeout="5000"
      :color="snackbarColor"
      location="center top"
    >
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<style scoped>

</style>
